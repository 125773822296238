import React from 'react'
import Helmet from "react-helmet"
import styled from 'styled-components';

const BASE_URL = 'https://www.sam-huang.info'

function jump(url) {
    window && (window.location.href = url)
}

const Redirect = props => {
    const pageData = props.pageContext.redirectData
    const forceJump = props.pageContext.force
    const [fromRevtel, setFromRevtel] = React.useState(false)

    const [shortUrl, targetUrl, note, _og = {}] = pageData
    let og = {}

    if (Array.isArray(_og)) {
        og = {
            title: _og[0] || note || '',
            description: _og[1] || '',
            image: _og[2] || '',
            url: targetUrl,
        }

    } else {
        og = {
            title: _og.title || note || '',
            description: _og.description || '',
            image: _og.image || '',
            url: targetUrl,
        }
    }

    //og.url = BASE_URL + `/${forceJump ? 'j' : 's'}/${shortUrl}`
    og.url = targetUrl

    React.useEffect(() => {
        setTimeout(() => jump(targetUrl), forceJump ? 1 : 500)

        if (window.location.host.toLocaleLowerCase().indexOf('revtel') !== -1) {
            setFromRevtel(true)
        }
    }, [])

    if (forceJump) {
        return <SEO og={og} />
    }

    return (
        <Wrapper>
            <SEO og={og} />

            <a className='action' href='/' target='_blank' style={{ cursor: 'pointer', flexDirection: 'flow', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
                <p style={{ fontSize: '2rem', fontWeight: 500, textDecoration: 'none', color: 'black' }}>{fromRevtel ? 'RevtelTech ' : ''}短網址轉換</p>
            </a>
            <div className='content'>

                <img src='/Fidget-spinner.gif' width='80' style={{ marginBottom: 24 }} />




                <h3 style={{ padding: 15, color: '#344D67' }}>0.5 秒後跳轉 ! 如無跳轉請點擊下方連結 !</h3>

                {
                    (function (target) {
                        return (
                            <p style={{ marginTop: 0, cursor: 'pointer', textDecoration: 'underline', color: '#8B7E74' }} onClick={() => window.open(target)}>
                                {splitLineToChunk(target, c => <p key={c} style={{ lineHeight: 0.5 }}>{c}</p>)}
                            </p>
                        )
                    })(targetUrl)
                }

                <p style={{ cursor: 'copy', marginTop: 24, padding: 15, backgroundColor: '#F5EBE0', borderRadius: 15 }} onClick={() => window && window.navigator.clipboard.writeText(targetUrl)}>複製網址</p>


                {(og.image || og.title || og.description) &&
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%', maxWidth: 350, marginTop: 20 }}>
                        <div style={{ backgroundColor: 'lightgrey', width: '80%', height: 2 }} />
                        {og.image && <img src={og.image} style={{ width: '100%', marginTop: 20 }} />}
                        {og.title && <p style={{ marginTop: 20, color: '#3D5656', fontSize: 18 }} >{og.title}</p>}
                        {og.description && <p style={{ marginTop: 20, color: '#3D5656', fontSize: 16 }} >{og.description}</p>}
                    </div>

                }




            </div>
            <a className='action' href='/' target='_blank' style={{ cursor: 'pointer', flexDirection: 'flow', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
                <img src='/left-arrow.png' width='36' />
            </a>
        </Wrapper>
    );
}

const SEO = ({ og }) => {

    let default_og_image = '/rt/1.png'
    try {
        const idx = ((og.title || '簡易轉址').split('').map(w => w.charCodeAt(0)).reduce((acc, val) => acc + val, 0)) % 9
        default_og_image = '/rt/' + idx + '.png'
    } catch (err) {
        default_og_image = '/rt/1.png'
    }

    let image = ''
    if (og.image) {
        image = (og.image.indexOf('https://') !== 0 ? BASE_URL : '') + og.image
    } else {
        image = BASE_URL + default_og_image
    }

    console.log('.....', og.title)

    const seo = {
        title: og.title || '簡易轉址',
        image,
        description: og.description || ' ',
        url: og.url || BASE_URL
    }

    return (
        <Helmet

            title={seo.title}
            meta={[
                {
                    name: `description`,
                    content: seo.description
                },
                {
                    property: `image`,
                    content: seo.image
                },
                {
                    property: `name`,
                    content: seo.title
                },
                {
                    property: `og:title`,
                    content: seo.title
                },
                {
                    property: `og:url`,
                    content: seo.url
                },
                {
                    property: `og:site_name`,
                    content: 'Short URL | Sam Huang Info',
                },
                {
                    property: `og:image`,
                    content: seo.image
                },
                {
                    property: `og:description`,
                    content: seo.description
                },
                {
                    property: 'og:url',
                    content: seo.url,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:image`,
                    content: seo.image
                },
                {
                    name: `twitter:creator`,
                    content: 'Sam Huang,黃奕翔'
                },
                {
                    name: `twitter:title`,
                    content: seo.title
                },
                {
                    name: `twitter:description`,
                    content: seo.description
                },
                {
                    property: 'keywords',
                    content: 'Sam Huang, Sam, sailplaneTW, 黃奕翔, 賣飛機的, 忻旅科技, Revtel, RevtelTech, 奕果雲端, Eculture, EcultureTech, React, React Native, Inigma, Tel25, Tel-U, 加密鏈, 軟體開發, 軟體顧問, 新創營運, web3, 區塊鏈',
                },

                {
                    name: 'robots',
                    content: 'noindex, nofollow'
                },
            ]}
        />
    )
}

function splitLineToChunk(s, func) {
    return s.match(/.{1,30}/g).map(func)

}

const Wrapper = styled.div`
    min-height: 98vh;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .action {
        display: flex;
        flex-direction: row;
        //padding: 15px;
        width:100%;
        height:64px;
        background-color: #EEEEEE;
    }

    & > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex:1;
        width:100%;
        padding-top:50px;
        padding-bottom:50px;
        //background-color: #FEFCF3;
    }
`



// ---


Redirect.displayName = 'RedirectPage';

export default Redirect;
